<template>
    <div class="container">
    <h3>出展予定企業一覧</h3>
      <div class="row">
        <div class="col-sm-4" v-for="company in shuffle" v-bind:key="company.name" >
          <div class="card">
            <div class="card-header">
              {{company.name}}
            </div>
            <div class="ybbox">
              <img :src="'img/'+company.id+'.jpg'" class="card-img-top" alt="...">
              <div  v-if="company.videoURL" class="ytbutton">
                <a v-if="company.videoURL" :href="company.videoURL" target="YB"><i class="fab fa-youtube fa-3x"></i></a>
              </div>
            </div>
            <div class="card-body">
              <p>{{company.description}}</p>
              <center>
                <a v-if="company.email" :href="'mailto:'+company.email+'?subject=jobway.tokyo%E7%B5%8C%E7%94%B1%E3%81%A7%E3%81%AE%E8%B3%AA%E5%95%8F%E3%81%A7%E3%81%99&amp;body=%E4%BB%A5%E4%B8%8B%E3%81%AE%E5%90%84%E9%A0%85%E7%9B%AE%E3%81%AB%E5%85%A5%E5%8A%9B%E3%81%97%E3%80%81%E9%80%81%E4%BF%A1%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82%0D%0A%E3%83%BB%E5%B9%B4%E9%BD%A2%E3%80%80%0D%0A%E3%83%BB%E6%80%A7%E5%88%A5%E3%80%80%0D%0A%E3%83%BB%E8%B3%AA%E5%95%8F%E5%86%85%E5%AE%B9%0D%0A'" type="button" class="btn btn-warning btn-sm">気軽に質問</a>
                <a :href="jobwayBASEURL+company.id" target="_BLANK" type="button" class="btn btn-warning btn-sm">求人を確認</a>
              </center>
            </div>
            <div class="card-body" style="border-width: thin">
              <div class="gyoshu">
                <span v-for="gyoshu in company.gyoshu" v-bind:key="gyoshu">
                    <button type="button" class="btn btn-outline-primary btn-sm">{{gyoshu}}</button>
                </span>
              </div>
              <div class="shokushu">
                <span v-for="shokushu in company.shokushu" v-bind:key="shokushu">
                    <button type="button" class="btn btn-outline-success btn-sm">{{shokushu}}</button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
 
<script>
import axios from 'axios';
import _ from 'lodash';
  export default {
    name: 'Companies',
    data() {
      return {
        jobwayBASEURL: "https://www.jobway.jp/member/company/view?c_keyyear=2022&id=",
        companies: null,
      };
    },
    created: function() {
      axios
        .get("../assets/data.json")
        .then(response => {
            this.companies = response.data;
        })
    },
    computed: {
        shuffle() {
            const companies = this.companies;
            return _.shuffle(companies);
        }
    }
  }
</script>
 
<style>
  h3 {
    padding: 1rem 1rem;
    border-left: 8px solid #12296F;

  }
  .card {
      margin-bottom: 15px;
  }
  div.card-body {
      padding: 5px;
      margin: 0;
  }
  div.card-body p {
      margin-bottom: 10px;
  }
  .btn {
      margin: 1px;
  }
  .ybbox {
    position: relative;
  }
  .ytbutton {
    position: absolute;
    top: 40%;
    left: 48%
  }
  .fa-youtube {
    color: #DA1725;
  }
</style>